<template>
    <b-card>
        <b-row>
            <!--  Info-->
            <b-col cols="21"
                   xl="6"
                   class="d-flex justify-content-between flex-column">

                <table class="mt-2 mt-xl-0 w-100">
                    <tr>
                        <th class="pb-50">
                            <feather-icon icon="CreditCardIcon"
                                          class="mr-75" />
                            <span class="font-weight-bold">{{$t('bank.name')}}</span>
                        </th>
                        <td class="pb-50">
                            {{ modelData.name }}
                        </td>
                    </tr>
                    <tr>
                        <th class="pb-50">
                            <feather-icon icon="ZapIcon"
                                          class="mr-75" />
                            <span class="font-weight-bold">{{$t('bank.status')}}</span>
                        </th>
                        <td class="pb-50 text-capitalize">
                            {{ $t(`status.${modelData.status}`) }}
                        </td>
                    </tr>
                </table>
            </b-col>
        </b-row>
    </b-card>
</template>

<script>
    import {
        BCard, BButton, BAvatar, BRow, BCol,
    } from 'bootstrap-vue'

    export default {
        components: {
            BCard, BButton, BRow, BCol, BAvatar,
        },
        props: {
            modelData: {
                type: Object,
                required: true,
            },
        },
        setup() {
            return {
            }
        },
    }
</script>

<style>
</style>
